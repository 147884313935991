import {Controller} from '@hotwired/stimulus';
import {ClassicEditor, Bold, Essentials, Heading, Italic, List, Link, AutoLink, Paragraph, Underline} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

export default class extends Controller {
    static targets = [
        'editor',
    ];

    connect() {
        ClassicEditor
            .create(this.element, {
                plugins: [Bold, Essentials, Heading, Italic, List, Link, AutoLink, Paragraph, Underline],
                toolbar: [
                    'undo', 'redo', '|', 'heading', '|',
                    'bold', 'italic', 'underline', '|',
                    'bulletedList', 'numberedList', '|', 'link',
                ],
                list: {
                    properties: {
                        styles: true,
                        startIndex: true,
                        reversed: true
                    }
                },
                link: {
                    decorators: {
                        addTargetToExternalLinks: {
                            mode: 'automatic',
                            callback: url => /^(https?:)?\/\//.test(url),
                            attributes: {
                                target: '_blank',
                                rel: 'noopener noreferrer nofollow'
                            }
                        },
                        defaultProtocol: 'https://',
                        toggleDownloadable: {
                            mode: 'manual',
                            label: 'Downloadable',
                            attributes: {
                                download: 'file'
                            }
                        }
                    }
                },
            })
            .then(editor => {
                window.preventPasteFromOfficeNotification = true;

                // attach a div after the editor textarea for 'preview mode'
                const previewDiv = document.createElement('div');
                previewDiv.className = 'editor-preview';
                editor.sourceElement.insertAdjacentElement('afterend', previewDiv);

                editor.model.document.on('change:data', () => {
                    editor.updateSourceElement();
                    this.editorTarget.dispatchEvent(new Event('change', {bubbles: true}));
                    // update the HTML from preview div
                    previewDiv.innerHTML = editor.sourceElement.value;
                });
            })
            .catch(error => {
                console.error(error.stack);
            });
    }
}