import "../scss/phoenix.scss";
import { dispatchTurboBeforeCacheEvents } from "./lib/turbo_cache_helper";
import { disableSubmitButtons, enableSubmitButtons, showSaveSuccessAnimation } from "./lib/submit_button_disabler";

// Stimulus
import {startStimulusApp} from "@symfony/stimulus-bridge";
import Notification from 'stimulus-notification';

// amcharts
import * as am5 from "@amcharts/amcharts5";
am5.addLicense("AM5C444094626");

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
export const app = startStimulusApp(require.context(
    "@symfony/stimulus-bridge/lazy-controller-loader!./stimulus-controllers/",
    true, // search recursively
    /\.(j|t)sx?$/
));

// Additional controllers (that are not part of Symfony UX)
app.register('notification', Notification);

// Turbo globally disable buttons
document.addEventListener('turbo:submit-start', (event) => {
    disableSubmitButtons(event);
})

// Turbo globally enable buttons
document.addEventListener('turbo:submit-end', (event) => {
    enableSubmitButtons(event);

    // check if the form is inside a turbo-frame
    let formInsideTurboFrame = event.detail.formSubmission.submitter?.closest('turbo-frame') != null;

    // set a success attribute to the frame which we later (turbo:frame-load & showSaveSuccessAnimation) use for animation classes
    if (formInsideTurboFrame && event.detail?.success === true) {
        let savedFrame = event.detail.formSubmission.submitter.closest('turbo-frame');
        savedFrame.setAttribute("success", "true");
    }
})

// Turbo check if save animation should be shown
document.addEventListener('turbo:frame-load', (event) => {
    let turboFrame = event.target;

    if(turboFrame.hasAttribute("success") && turboFrame.hasAttribute("show-success")) {
        showSaveSuccessAnimation(turboFrame);
    }
})

dispatchTurboBeforeCacheEvents();