import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        "canvas", // The card wrapper on which the class should be toggled
        "input", // The color input
        "hiddenFileInput", // The file input where we set the generated image
    ];

    static values = {
        backgroundColor: String,
        imgSrc: String
    }

    connect() {
        this.setBackgroundColor();
        this.setImage();
    }

    // Set the background color of the canvas element
    setBackgroundColor() {
        if (this.backgroundColorValue === '') {
            return;
        }

        const ctx = this.canvasTarget.getContext('2d');  // get the 2D context of the canvas
        ctx.fillStyle = this.backgroundColorValue; // set the background color
        ctx.fillRect(0, 0, this.canvasTarget.width, this.canvasTarget.height);  // fill the canvas
        this.generateImage();
    }

    // Set the image to the canvas element
    setImage() {
        // create a new image
        const image = new Image();
        image.src = this.imgSrcValue;

        // wait until the image is loaded before drawing it
        image.onload = () => {
            const ctx = this.canvasTarget.getContext('2d');  // get the 2D context of the canvas
            ctx.drawImage(image, 0, 0, this.canvasTarget.width, this.canvasTarget.height); // position left top (0, 0) and size (340x340)
            this.generateImage();
        };
    }

    // Generate the image and write it to the file input for upload
    generateImage() {
        // Convert the canvas to a Blob (340x340 px as per canvas size)
        this.canvasTarget.toBlob((blob) => {
            if (blob) {
                // Create a file from the blob
                const file = new File([blob], "community-image.png", { type: "image/png" });

                // Use a dataTransfer object to update the file input
                const dataTransfer = new DataTransfer();
                dataTransfer.items.add(file);
                this.hiddenFileInputTarget.files = dataTransfer.files;
                this.hiddenFileInputTarget.dispatchEvent(new Event('change', { bubbles: true }));
            }
        }, "image/png");
    }
}
