import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        "recipientGroup", // <select> for recipientGroup
        "communityContainer", // <div> container of community select
        "community", // <select> for community
        "preview", // preview switch
        "emailContent", // email preview container
        "textarea", // all textareas (content & salutation)
    ];

    connect() {
        this.toggleCommunitySelect();

        // set a min-height to the preview container, so the height-change from non-preview to preview
        // is not that big.
        this.emailContentTargets.forEach(emailContent => {
            let containerHeight = emailContent.clientHeight;

            emailContent.style.minHeight = containerHeight + "px";
        });
    }

    toggleCommunitySelect() {
        if (!this.hasRecipientGroupTarget) {
            return;
        }

        if (this.recipientGroupTarget.value === 'PARTICIPANTS_IN_SPECIFIC_COMMUNITY') {
            this.communityContainerTarget.removeAttribute('hidden');
            this.communityTarget.setAttribute('required', 'required');
        }
        else {
            this.communityContainerTarget.setAttribute('hidden', 'hidden');
            this.communityTarget.removeAttribute('required');
        }
    }

    togglePreview(e) {
        const previewSwitch = e.target;
        const parentPreview = previewSwitch.closest('.email');

        if(previewSwitch.checked) {
            parentPreview.classList.add('is--preview');
            parentPreview.classList.add('is--loading');
            this.setTextAreaHeight(parentPreview);

            setTimeout(()=> {
                parentPreview.classList.remove('is--loading');
            }, 1000);
        }
        else {
            parentPreview.classList.remove('is--preview');
            parentPreview.classList.add('is--loading');
            this.removeTextAreaHeight(parentPreview);

            setTimeout(()=> {
                parentPreview.classList.remove('is--loading');
            }, 1000);
        }
    }

    // when in preview mode, the textarea needs the height as inline style
    // because it is possible that the content overflows the initial height
    setTextAreaHeight(parentPreview) {
        let textAreas = parentPreview.querySelectorAll('textarea');

        textAreas.forEach(textarea => {
            let textHeight = textarea.scrollHeight;
            textarea.style.height = textHeight + "px";
        });
    }

    removeTextAreaHeight(parentPreview) {
        let textAreas = parentPreview.querySelectorAll('textarea');

        textAreas.forEach(textarea => {
            if (!textarea.hasAttribute('data-ck-editor-target')) {
                textarea.removeAttribute("style");
            }
        });
    }
}
