import { Controller } from '@hotwired/stimulus';
import {calculatePasswordScore} from '../lib/password_strength';

export default class extends Controller {
    static targets = [
        'input',              // the password field
        'strength',           // the strength div (the whole colored bar + the text inside)
        'weakStrengthText',   // the "weak" text field (which will be shown or hidden depending on strength)
        'mediumStrengthText', // the "medium" text field (which will be shown or hidden depending on strength)
        'strongStrengthText', // the "strong" text field (which will be shown or hidden depending on strength)
    ];

    connect() {
        // register custom Event listener to check after generating password
        document.addEventListener('passwordStrength:check', (e) => this.check());
    }

    check() {
        if (this.inputTarget.value === '') {
            this.strengthTarget.classList.remove('is--visible', 'is--weak', 'is--medium', 'is--strong');
            this.weakStrengthTextTarget.classList.remove('is--visible');
            this.mediumStrengthTextTarget.classList.remove('is--visible');
            this.strongStrengthTextTarget.classList.remove('is--visible');

            return;
        }

        this.strengthTarget.classList.add('is--visible');

        const score = calculatePasswordScore(this.inputTarget.value);

        if (score < 4) {
            this.strengthTarget.classList.remove('is--medium', 'is--strong');
            this.strengthTarget.classList.add('is--weak');

            this.weakStrengthTextTarget.classList.add('is--visible');
            this.mediumStrengthTextTarget.classList.remove('is--visible');
            this.strongStrengthTextTarget.classList.remove('is--visible');
        } else if (score === 4) {
            this.strengthTarget.classList.remove('is--weak', 'is--strong');
            this.strengthTarget.classList.add('is--medium');

            this.weakStrengthTextTarget.classList.remove('is--visible');
            this.mediumStrengthTextTarget.classList.add('is--visible');
            this.strongStrengthTextTarget.classList.remove('is--visible');
        } else {
            this.strengthTarget.classList.remove('is--weak', 'is--medium');
            this.strengthTarget.classList.add('is--strong');

            this.weakStrengthTextTarget.classList.remove('is--visible');
            this.mediumStrengthTextTarget.classList.remove('is--visible');
            this.strongStrengthTextTarget.classList.add('is--visible');
        }
    }
}
