import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        "trigger", // The button which triggers copying to clipboard
        "source", // The source which is copied
    ];

    copy() {
        navigator.clipboard.writeText(this.sourceTarget.value);

        setTimeout(()=> {
            this.triggerTarget.classList.add('is--success');
        }, 100);

        setTimeout(()=> {
            this.triggerTarget.classList.remove('is--success');
        }, 1500);
    }
}
