import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        "trigger", // The toggle Button
        "toggle", // The element on which the class should be toggled
    ];

    connect() {
        // register custom Event listener to close all simple toggles before taking turbo snapshot
        document.addEventListener('simple-toggle:hide-all', (e) => this.hideAllBeforeTurboSnapshot(e));
    }

    hideAllBeforeTurboSnapshot(e) {
        this.toggleTargets.forEach((toggleTarget) => {
            this.hide(e, true);
        });
    }

    toggle(event) {
        event.stopPropagation();
        event.preventDefault();

        const isExpanded = this.triggerTarget.hasAttribute('aria-expanded');

        if (isExpanded) {
            this.hide();
        } else {
            this.show();
        }
    }

    show() {
        this.triggerTarget.setAttribute('aria-expanded', 'true');
        this.toggleTarget.classList.add('is--visible');
    }

    hide() {
        this.triggerTarget.removeAttribute('aria-expanded');
        this.toggleTarget.classList.remove('is--visible');
    }

    hideAll() {
        this.triggerTargets.forEach(list => {
            this.triggerTarget.removeAttribute('aria-expanded');
        });

        this.toggleTargets.forEach(list => {
            list.classList.remove('is--visible');
        });
    }
}
