import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        "fileInput", // <select> for recipientGroup
        "loader", // <span> loading animation
        "fileName", // <span> which we add the filename to
        "mimeTypeError", // <span> error message for wrong mimetype
        "submit", // <div> with submit button
    ];

    static values = {
        mimeTypes: Array,
    }

    connect() {
        const dropArea = this.element;
        // get allowed mime types for upload from accept attribute
        this.mimeTypesValue = this.fileInputTarget.accept.split(',').map(type => type.trim());

        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function(event) {
            dropArea.addEventListener(event, function(e) {
                e.preventDefault();
                e.stopPropagation();
            });
        });

        // show active state
        dropArea.addEventListener('dragenter', (e) => {
            dropArea.classList.add('is--active');
        }, false);

        dropArea.addEventListener('dragover', (e) => {
            dropArea.classList.add('is--active');
        }, false);

        // remove active state
        dropArea.addEventListener('dragleave', (e) => {
            dropArea.classList.remove('is--active');
        }, false);

        dropArea.addEventListener('drop', (e) => {
            dropArea.classList.remove('is--active');
            this.handleDrop(e);
        }, false);
    }

    // case: user uses the input[type="file"] and picks a file.
    handleFile() {
        let fileInput = this.fileInputTarget.files[0];

        if(fileInput) {
            // hide the filename
            this.fileNameTarget.setAttribute('hidden', 'hidden');
            // hide the error message
            this.mimeTypeErrorTarget.setAttribute('hidden', 'hidden');
            // show loading animation
            this.loaderTarget.removeAttribute('hidden');

            // check if the mime type is allowed
            let fileTypeAllowed = this.mimeTypeIsAllowed(fileInput.name);

            setTimeout(() => {
                this.loaderTarget.setAttribute('hidden', 'hidden'); // hide the loading animation

                if(fileTypeAllowed) {
                    // if everything is fine, we show the filename in the span
                    this.fileNameTarget.textContent = fileInput.name;
                    this.fileNameTarget.removeAttribute('hidden'); // show the span that holds the filename
                    this.submitTarget.removeAttribute('hidden');
                }
                else {
                    this.resetFileUpload();
                    // show the error message when a wrong mimetype was dropped
                    this.mimeTypeErrorTarget.removeAttribute('hidden');
                }
            }, 500);
        }
        else {
            this.resetFileUpload();
        }
    }

    // reset to initial state
    resetFileUpload() {
        // hide the filename
        this.fileNameTarget.setAttribute('hidden', 'hidden');
        // hide the error message
        this.mimeTypeErrorTarget.setAttribute('hidden', 'hidden');
        // hide the submit button
        this.submitTarget.setAttribute('hidden', 'hidden');
    }

    // case: User drops a file into the area
    handleDrop(e) {
        e.preventDefault();
        let dt = e.dataTransfer;
        let files = dt.files;

        // create a new DataTransfer object, so we can assign files to the file input later
        const dataTransfer = new DataTransfer();

        // add the (first) file to the dataTransfer object
        // should we at some point need multiple files, we can iterate over the files[]
        dataTransfer.items.add(files[0]);

        // assign the files to the file input
        this.fileInputTarget.files = dataTransfer.files;

        // trigger the change event which calls 'handleFile()'
        this.fileInputTarget.dispatchEvent(new Event("change", { bubbles: true }));
    }

    // check if the mime type of the chosen file is within the 'accept' attribute of the file input
    mimeTypeIsAllowed(filename) {
        // split the filename by dots
        const fileParts = filename.split('.');

        // check if there is an extension and return the last part of the array (the extension)
        // just in case there is a file name like 'filename.bla.xlsx' with two or more dots.
        let uploadedFileType = fileParts.length > 1 ? ('.' + fileParts.pop()) : '';

        // check if fileType is in list of this.mimeTypesValue
        return this.mimeTypesValue.includes(uploadedFileType);
    }
}
