import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        this.element.addEventListener('keypress', (e) => {
            // if the user hits ctrl+enter the form is submitted
            // e.metaKey macOS
            if ((e.ctrlKey || e.metaKey) && (e.keyCode === 13 || e.keyCode === 10)) {
                const form = this.element.closest('form'); // find the parent form tag
                const submitButton = form.querySelector('[type="submit"]'); // get the submit button
                submitButton.click(); // trigger click on the submit button
            }
        });
    }
}