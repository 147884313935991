import ColorPicker from 'stimulus-color-picker';

export default class extends ColorPicker {
    static targets = [
        "input", // The input which holds the #HEX code
    ];

    static values = {
        'type': String,
        'id': String,
        'initialColor': String,
    };

    initialize() {
        super.initialize();
    }

    connect() {
        super.connect();
        if (this.inputTarget.value === '') {
            this.picker._root.button.classList.add('clear')
        }
    }

    // Callback when the color is saved (within the open color picker!)
    onSave(color) {
        super.onSave(color);
        this.dispatchChangeEvent();
    }

    update() {
        this.picker.setColor(this.inputTarget.value);
        this.picker.applyColor(false);
    }

    reset() {
        this.picker.setColor(this.initialColorValue);
        this.picker.applyColor(false);
    }

    dispatchChangeEvent(){
        this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }));
    }

    disconnect() {
        super.disconnect();
    }
}
