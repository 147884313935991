import { Controller } from '@hotwired/stimulus'

// Derived from https://github.com/stimulus-components/stimulus-password-visibility
export default class extends Controller {
    static targets = [
        'input', // The password field
        'icon'   // The icon(s)
    ];

    static classes = [
        'hidden' // The class to toggle on the icon(s)
    ];

    connect() {
        this.hidden = this.inputTarget.type === 'password';
        this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden';

        document.addEventListener('password-visibility:clear-password-field', (e) => this.clearPasswordField());
    }

    clearPasswordField() {
        this.inputTarget.value = '';
    }

    toggle(e) {
        e.preventDefault();
        this._doToggle();
    }

    show() {
        if (this.hidden) {
            this._doToggle();
        }
    }

    hide() {
        if (!this.hidden) {
            this._doToggle();
        }
    }

    _doToggle() {
        this.inputTarget.type = this.hidden ? 'text' : 'password';
        this.hidden = !this.hidden;
        this.iconTargets.forEach(icon => icon.classList.toggle(this.class));
    }
}
