import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
        static targets = [
            'profileAvatar'
        ]

        updateAvatar() {
            document.getElementById('sidebar-user-image').src = this.profileAvatarTarget.src;
        }
    }