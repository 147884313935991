// See:
//  https://github.com/rollerworks/PasswordStrengthValidator/issues/36
//  https://github.com/rollerworks/PasswordStrengthValidator/blob/main/docs/strength-validation.md
export function calculatePasswordScore(password) {
    let strength = 0;

    // Does the password contain an alpha character?
    if (password.match(/[a-zA-Z]/)) strength++;

    // Does the password contain a digit?
    if (password.match(/\d+/)) strength++;

    // Does the password contain both lowercase and uppercase alpha characters?
    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) strength++;

    // Does the password contain a special character (non-alpha/digit)?
    if (password.match(/[^a-zA-Z0-9]/)) strength++;

    // Does the password have a length of at least 13 characters?
    if (password.length >= 13) strength++;

    // Extra rule: Minimum length
    if (password.length < 12) {
        strength = Math.min(3, strength);
    }

    return strength;
}
