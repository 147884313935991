import {Controller} from '@hotwired/stimulus';

// Used to update the <html lang> attribute correctly, since Turbo would not update it normally. We expect a "language"
// attribute on the <body> element for this to work.
export default class extends Controller {
    update(e) {
        document.documentElement.lang = e.detail.newBody.dataset.language;
        // on profile pages: also refresh the new date format for this language
        if (e.detail.newBody.getAttribute('data-date-format')) {
            document.documentElement.setAttribute('data-date-format', e.detail.newBody.dataset.dateFormat);
        }
    }
}
