export function removeAllErrorsBeforeTurboSnapshot() {
    // remove 'general' flash box
    let flashMessageContainer = document.querySelector('.notification-aria');
    if (flashMessageContainer !== null) {
        flashMessageContainer.remove();
    }
    // flashes inside pause form
    flashMessageContainer = document.querySelector('div[role="alert"]');
    if (flashMessageContainer !== null) {
        flashMessageContainer.remove();
    }

    const forms = document.querySelectorAll('form');
    forms.forEach(function(form) {
        // remove form field error above the field
        const generalFieldError = form.querySelector('.alert');
        if (generalFieldError !== null) {
            generalFieldError.remove();
        }

        // remove error message below the field
        const lowerFieldError = form.querySelector('.field__error');
        if (lowerFieldError !== null) {
            lowerFieldError.remove();
        }
    });
}