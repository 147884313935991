import {Controller} from '@hotwired/stimulus';

let email_list = [];
let has_error = false;

export default class extends Controller {
    static targets = [
        "emailsWrapper", // wrapper for "add emails"
        "input", // the email input
        "emails", // container to which the entered email addresses are added
        "error", // error message if entered email is not valid
        "hiddenInput", // hidden input fields used for values
        "allowedDomains", // hidden input for allowed domain list
        "participantEmail", // the current participant email,
        "errorNotBlank", // error message email must not be blank
        "errorCannotInviteYourself", // error message cannot invite yourself
        "errorFormat", // error message format invalid
        "errorDomain", // error message domain not allowed
        "errorAlreadyExists", // error message email already added,
        "info" // info alert
    ];

    static values = {
        counter: 0, // number of email addresses entered
        focusInputField: true, // number of email addresses entered
    }

    connect() {
        this.allowedEmailDomains = JSON.parse(this.allowedDomainsTarget.value);

        // make sure the email addresses can be added via enter key and space key is disabled
        this.inputTarget.addEventListener('keypress', (e) => {
            // disable space key, because it's not allowed in emails
            if(e.keyCode === 32) {
                e.preventDefault();
            }

            // make sure the email addresses can be added via enter key
            if (e.keyCode === 13) {
                e.preventDefault();
                this.addEmail();
            }
        });

        // remove all spaces from the entered email address (if entered via copy paste)
        this.inputTarget.addEventListener('keyup', (e) => {
            this.inputTarget.value = this.inputTarget.value.replace(/\s/g,'');
        });

        // if the hidden field has a value (meaning a server error occured) the sent state needs to be rebuilt
        if(this.hiddenInputTarget.value.trim().length > 0) {
            const email_addresses = this.hiddenInputTarget.value.replace(/ /g,''); // get the value and remove all spaces
            const mails = email_addresses.split(','); // save all emails to an array

            // rebuild the before send state
            mails.forEach(email => this.addChip(email));
            this.inputTarget.focus();
        }

        // before submitting the form check if there is something entered
        // A valid email is added to the hidden field. An invalid input will show an error
        const submitButton = this.element.querySelector('[type="submit"]'); // get the clicked submit button

        submitButton.addEventListener('click', (e) => {
            this.focusInputFieldValue = false; // don't focus the input field after clicking send.
            this.addEmail();

            // check if at least one email address was entered. if not don't submit the form and show the error message
            if(this.hiddenInputTarget.value.trim().length === 0) {
                e.preventDefault();

                const email = this.inputTarget.value.trim();
                // show the not blank error message
                const email_blank = this.validateEmailBlank(email);

                if(email_blank) {
                    this.hideErrors();
                    this.errorNotBlankTarget.removeAttribute("hidden");
                    this.errorTarget.removeAttribute("hidden"); // show the field error
                    this.inputTarget.setAttribute("aria-describedby", "recommendEmailError");
                    has_error = true;
                }

                this.inputTarget.focus();
            }

            if(has_error) {
                e.preventDefault();
                this.inputTarget.focus();
            }
        });
    }

    // Add entered email if valid
    addEmail() {
        // do nothing if only spaces were added
        if(this.inputTarget.value.trim().length === 0) {
            this.resetInput();
            has_error = false;
            return;
        }

        // get the email address and check for validity
        const email_address = this.inputTarget.value.trim().toLowerCase();
        const email_valid = this.validateEmail(email_address);

        if(email_valid) {
            // add the chip to the container
            this.addChip(email_address);
            this.resetInput();
            this.checkCounter();
            has_error = false;
        }
        else {
            // show the field error
            this.errorTarget.removeAttribute("hidden");
            this.inputTarget.setAttribute("aria-describedby", "recommendEmailError");
            has_error = true;
        }
    }

    // Remove clicked 'x' email
    removeEmail(e) {
        const email_item = e.currentTarget.closest('li');
        const email_address = email_item.querySelector('.email-chip__address').textContent.trim(); // find the clicked email address
        email_item.remove(); // remove the clicked email from the DOM
        email_list = email_list.filter(email => email !== email_address); // remove the clicked email from the array
        this.counterValue -= 1;
        this.updateValues(); // update the value in the hidden field
        this.checkCounter();
    }

    // Clear all entered emails from hidden input and email array after submit
    clearEmails() {
        this.hiddenInputTarget.value = '';
        email_list = [];
    }

    // Set container visiblity depending on the number of items (0 = hidden, > 0 visible)
    setContainerVisibility() {
        if(this.counterValue > 0) {
            this.emailsTarget.classList.add('is--visible');
        }
        else {
            this.emailsTarget.classList.remove('is--visible');
            this.inputTarget.focus();
        }
    }

    // Create the HTML markup for the email chips
    createEmailChip(email) {
        let deleteText = this.emailsWrapperTarget.dataset.translation;
        deleteText = deleteText.replace("__email__", email); // replace the text __email__ from translation key with actual added email address

        let item = document.createElement('li');
        item.classList.add('email-chip__item')

        let item_html = '<span class="email-chip__address" tabindex="0">' + email + '</span>'
            + '<button class="email-chip__delete" type="button" data-action="multiple-emails#removeEmail">'
            + '<svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="10" fill="currentColor">'
            + '<path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>'
            + '</svg>'
            + '<span class="visually-hidden">' + deleteText + '</span>'
            + '</button>';

        item.innerHTML = item_html.trim();

        // Change this to div.childNodes to support multiple top-level nodes.
        return item;
    }

    // Append the chip to the container
    addChip(email_address) {
        const chip = this.createEmailChip(email_address); // create a chip with the entered email address
        this.emailsTarget.append(chip); // append the chip to the list in the dom
        email_list.push(email_address); // add the email to the array
        this.counterValue += 1; // increase the counter
        this.updateValues(); // update the value in the hidden field
    }

    // Check the email counter to enable/disbale the input and show/hide the info alert
    checkCounter() {
        if(this.counterValue >= 100) {
            this.infoTarget.removeAttribute("hidden");
            this.inputTarget.setAttribute('disabled', '');
        }
        else {
            this.inputTarget.removeAttribute('disabled');
            if(this.focusInputFieldValue === true) {
                this.inputTarget.focus(); // focus the email input again
            }
            this.infoTarget.setAttribute('hidden', '');
        }
    }

    // Add the entered emails to the hidden field
    updateValues() {
        this.hiddenInputTarget.value = email_list.toString();
        this.setContainerVisibility();
    }

    // Reset the input to the initial state
    resetInput() {
        this.inputTarget.value = ''; // clear the input

        if(this.focusInputFieldValue === true) {
            this.inputTarget.focus(); // focus the email input again
        }
        this.inputTarget.removeAttribute("aria-describedby"); // remove the connection to the error message
        this.errorTarget.setAttribute("hidden", ''); // hide the error container
    }

    // Validate the email format, allowed domain, existence in list, ...
    validateEmail(email) {
        let emailValidRegex = new RegExp(this.inputTarget.pattern);
        const emailFormatValid = this.inputTarget.checkValidity();
        const email_domain_valid = this.validateEmailDomain(email);
        const email_equals_participant = this.validateEmailParticipant(email);
        const email_already_exists = this.validateEmailAlreadyExists(email);

        this.hideErrors();

        if(!emailFormatValid) {
            this.errorFormatTarget.removeAttribute("hidden");
            return false;
        }

        if(!email_domain_valid) {
            this.errorDomainTarget.removeAttribute("hidden");
            return false;
        }

        if(email_equals_participant) {
            this.errorCannotInviteYourselfTarget.removeAttribute("hidden");
            return false;
        }

        if(email_already_exists) {
            this.errorAlreadyExistsTarget.removeAttribute("hidden");
            return false;
        }

        return true;
    }

    // Validate if email has an allowed domain
    validateEmailDomain(email) {
        const email_domain = email.substring(email.indexOf('@') + 1);

        // if allowed domains array is empty, so all domains are allowed
        if(this.allowedEmailDomains.length === 0) {
            return true;
        }

        // for testing&debugging: allways allow the mysteryminds.com email domain
        if (email_domain === 'mysteryminds.com') {
            return true;
        }

        return this.allowedEmailDomains.indexOf(email_domain) >= 0;
    }

    // Check if the email field is empty
    validateEmailBlank() {
        return this.inputTarget.value.trim().length === 0;
    }

    // Validate if the entered email address is the same as the participant email
    validateEmailParticipant(email) {
        return email === this.participantEmailTarget.value;
    }

    // Validate if the email was already added to the list
    validateEmailAlreadyExists(email) {
        return email_list.indexOf(email) >= 0;
    }

    // Hide all error messages
    hideErrors() {
        this.errorNotBlankTarget.setAttribute('hidden', '');
        this.errorFormatTarget.setAttribute('hidden', '');
        this.errorDomainTarget.setAttribute('hidden', '');
        this.errorAlreadyExistsTarget.setAttribute('hidden', '');
        this.errorCannotInviteYourselfTarget.setAttribute('hidden', '');
    }
}