import {
    Controller
} from '@hotwired/stimulus'
import party from "party-js";

export default class extends Controller {
    static targets = [
        "source"
    ];

    initialize() {
        party.settings.gravity = 150;
    }

    connect() {
        this.rainConfetti();
    }

    rainConfetti() {
        party.confetti(this.sourceTarget, {count: 200, speed: 300});
    }
}