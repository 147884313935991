import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        "anchor", // The link on which the href will be set
    ];

    connect() {
        // if there is no data-has-history set on html tag, we update the back-link with the url from data-fallback-url
        if(!document.documentElement.hasAttribute("data-has-history")) {
            this.anchorTargets.forEach(anchor => {
                let fallbackUrl = anchor.dataset.fallbackUrl;
                anchor.setAttribute("href", fallbackUrl);
            });

            // for future visits we then have a browser history, so we set the data-has-history to true
            document.documentElement.setAttribute('data-has-history', 'true');
        }
    }
}
