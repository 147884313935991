import {German} from "flatpickr/dist/l10n/de";
import {english} from "flatpickr/dist/l10n/default";
import {French} from "flatpickr/dist/l10n/fr";
import {Italian} from "flatpickr/dist/l10n/it";
import {Spanish} from "flatpickr/dist/l10n/es";

export function getLocale(userLanguageCode) {
    switch (userLanguageCode) {
        case 'de' :
            return {
                inputMaskPattern: '`d{.}`m{.}`Y',
                flatpickrPattern: 'd.m.Y',
                flatpickrLanguage: German,
                validateFormat: 'DD.MM.YYYY',
            }

        case 'en' :
            return {
                inputMaskPattern: '`m{\/}`d{\/}`Y',
                flatpickrPattern: 'm/d/Y',
                flatpickrLanguage: english,
                validateFormat: 'MM/DD/YYYY',
            }

        case 'es' :
            return {
                inputMaskPattern: '`d{\/}`m{\/}`Y',
                flatpickrPattern: 'd/m/Y',
                flatpickrLanguage: Spanish,
                validateFormat: 'DD/MM/YYYY',
            }

        case 'fr' :
            return {
                inputMaskPattern: '`d{\/}`m{\/}`Y',
                flatpickrPattern: 'd/m/Y',
                flatpickrLanguage: French,
                validateFormat: 'DD/MM/YYYY',
            }

        case 'it' :
            return {
                inputMaskPattern: '`d{.}`m{.}`Y',
                flatpickrPattern: 'd.m.Y',
                flatpickrLanguage: Italian,
                validateFormat: 'DD.MM.YYYY',
            }

        default:
            throw new Error('Unsupported language: ' + userLanguageCode);
    }
}