import {Controller} from '@hotwired/stimulus'

export default class extends Controller {

    static targets = [
        "progress",
        "progressSVG",
        "progressValue",
        "progressAccessibilityText",
    ];

    update(event) {
        let percentage = event.detail.newFrame.getAttribute('data-profile-progress');
        this.progressTarget.style.width = percentage;
        this.progressValueTarget.innerHTML = percentage;
        this.progressAccessibilityTextTarget.innerHTML = percentage;
        this.toggleSVG(percentage);
    }

    toggleSVG(percentage) {
        if (percentage === '100%') {
            this.progressSVGTarget.classList.remove('is--hidden');
            return;
        }

        this.progressSVGTarget.classList.add('is--hidden');
    }
}