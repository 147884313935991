import { Controller } from '@hotwired/stimulus';
import { generate } from 'generate-password-browser';

export default class extends Controller {
    static targets = [
        "input", // The password field
    ];

    generate() {
        this.inputTarget.value = generate({
            length: 16,
            numbers: true,
            symbols: true,
            excludeSimilarCharacters: true,
            strict: true,
        });

        this.dispatch("generate");
        document.dispatchEvent(new Event('passwordStrength:check')); // check password strength just in case.
    }
}
