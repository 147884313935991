import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'showIcebreakerButton',
        'hideWhiteboardButton',
        'showWhiteboardButton',
        'video',
    ];

    static values = {
        avatarUrl: String,
        icebreakers: Array,
        icebreakerTranslation: String,
        jitsiToken: String,
        language: String,
        roomName: String,
        humanReadableRoomName: String,
    };

    connect() {
        this.launchCall();
    }

    launchCall() {
        this.initializeJitsiApi();
        this.controlTileView();
        this.addDebugEventListeners();
        this.initializeIcebreakers();
        this.initializeWhiteboard();
        this.handleLeave();
    }

    initializeJitsiApi() {
        this.api = new JitsiMeetExternalAPI("8x8.vc", {
            roomName: this.roomNameValue,
            parentNode: this.videoTarget,
            jwt: this.jitsiTokenValue,
            configOverwrite: {
                disableModeratorIndicator: false,
                disableThirdPartyRequests: true,
                localSubject: this.humanReadableRoomNameValue,
                disablePolls: true,
                speakerStats: {
                    disabled: true,
                },
                localRecording: {
                    disable: true,
                },
                liveStreaming: {
                    enabled: false,
                },
                transcription: {
                    enabled: false,
                },
                securityUi: {
                    hideLobbyButton: true,
                    disableLobbyPassword: true,
                },
                disableShortcuts: true,
                readOnlyName: true,
                gravatar: {
                    disabled: true,
                },
                prejoinConfig: {
                    hideExtraJoinButtons: ['no-audio', 'by-phone'],
                },
                recordings: {
                   showPrejoinWarning: false,
                },
                toolbarButtons: [
                    'camera',
                    'chat',
                    // 'closedcaptions', // Transcription has been disabled anyway
                    'desktop', // Share screen
                    // 'download', // No visible impact
                    // 'embedmeeting', // No visible impact
                    // 'etherpad', // Not integrated
                    // 'feedback', // Don't want to send data anywhere
                    //  'filmstrip', // No visible impact
                    // 'fullscreen', // Disabled intentionally
                    'hangup',
                    // 'help', // No visible impact
                    // 'highlight', // No visible impact
                    // 'invite', // Don't want external participants
                    // 'linktosalesforce', // Not integrated
                    // 'livestreaming', // Don't want anything to leave this room
                    'microphone',
                    // 'noisesuppression', // Can be seen in the audio settings anyway
                    // 'participants-pane', // Not required in 1:1 meetings
                    // 'profile', // Profile functionality has been disabled
                    // 'raisehand', // Not required in 1:1 meetings
                    // 'recording', // Don't want anything to leave this room
                    // 'security', // Would be empty since we disabled password and lobby settings
                    'select-background',
                    'settings',
                    // 'shareaudio', // Don't want anything to leave this room
                    // 'sharedvideo', // Not required
                    // 'shortcuts', // No visible impact
                    // 'stats', // No visible impact
                    'tileview',
                    // 'toggle-camera', // No visible impact
                    // 'videoquality', // Can be seen on top anyway
                    // 'whiteboard', // We control whiteboard visibility ourselves
                ],
                gatherStats: false,
                analytics: {
                    disabled: true,
                },
                deeplinking: {
                    disabled: true,
                },
                disableInviteFunctions: true,
                doNotStoreRoom: true,
                remoteVideoMenu: {
                    disableDemote: true,
                    disableKick: true,
                    disableGrantModerator: true,
                    disablePrivateChat: true,
                },
                disableAddingBackgroundImages: true,
                conferenceInfo: {
                    alwaysVisible: [
                        // 'recording',
                        // 'raised-hands-count'
                        // 'subject',
                        // 'participants-count',
                        // 'e2ee',
                        // 'video-quality',
                        // 'insecure-room',
                        // 'highlight-moment',
                        // 'top-panel-toggle',
                    ],
                    autoHide: [
                        'conference-timer',
                    ],
                },
                hideConferenceSubject: true,
            },
            interfaceConfigOverwrite: {
                MOBILE_APP_PROMO: false,
                PROVIDER_NAME: 'Mystery Minds',
                RECENT_LIST_ENABLED: false,
                SETTINGS_SECTIONS: ['devices'], // 'devices', 'language', 'moderator', 'profile', 'calendar', 'sounds', 'more'
                SHOW_JITSI_WATERMARK: false,
                SHOW_BRAND_WATERMARK: true,
            },
            lang: this.languageValue,
        });
    }

    controlTileView() {
        this.api.addEventListener('videoConferenceJoined', (data) => {
            this.api.executeCommand('setTileView', true);
        });
    }

    addDebugEventListeners() {
        this.api.addEventListener('errorOccured', (data) => {
            console.debug('meet_controller: event "errorOccured"', data);
        });

        this.api.addEventListener('participantJoined', (data) => {
            console.debug('meet_controller: event "participantJoined"', data);
        });

        this.api.addEventListener('participantLeft', (data) => {
            console.debug('meet_controller: event "participantLeft"', data);
        });

        this.api.addEventListener('videoConferenceLeft', (data) => {
            console.debug('meet_controller: event "videoConferenceLeft"', data);
        });

        this.api.addEventListener('readyToClose', () => {
            console.debug('meet_controller: event "readyToClose"');
        });

        this.api.addEventListener('peerConnectionFailure', (data) => {
            console.debug('meet_controller: event "peerConnectionFailure"', data);
        });

        this.api.addEventListener('p2pStatusChanged', (data) => {
            console.debug('meet_controller: event "p2pStatusChanged"', data);
        });
    }

    initializeIcebreakers() {
        this.icebreakerIndex = 0;

        this.api.addEventListener('videoConferenceJoined', () => {
            this.showIcebreakerButtonTarget.classList.remove('is--hidden');
        });

        this.api.addEventListener('videoConferenceLeft', () => {
            this.showIcebreakerButtonTarget.classList.add('is--hidden');
        });

        this.api.addListener('endpointTextMessageReceived', (data) => {
            const decodedData = JSON.parse(data.data.eventData.text);

            if (decodedData.action === 'icebreakerInitiated') {
                this.displayIcebreaker(decodedData.icebreaker);
            }
        });
    }

    initializeWhiteboard() {
        this.isWhiteboardVisible = false;

        this.api.addEventListener('videoConferenceJoined', () => {
            this.showWhiteboardButtonTarget.classList.remove('is--hidden');
        });

        this.api.addEventListener('videoConferenceLeft', () => {
            this.hideWhiteboardButtonTarget.classList.add('is--hidden');
            this.showWhiteboardButtonTarget.classList.add('is--hidden');
        });

        this.api.addListener('whiteboardStatusChanged', (data) => {
            this.isWhiteboardVisible = data.status === 'SHOWN';

            this.showWhiteboardButtonTarget.classList.toggle('is--hidden', this.isWhiteboardVisible);
            this.hideWhiteboardButtonTarget.classList.toggle('is--hidden', !this.isWhiteboardVisible);
        });

        this.api.addListener('endpointTextMessageReceived', (data) => {
            const decodedData = JSON.parse(data.data.eventData.text);

            switch (decodedData.action) {
                case 'showWhiteboardInitiated':
                    this.showWhiteboard();
                    break;

                case 'hideWhiteboardInitiated':
                    this.hideWhiteboard();
                    break;
            }
        });
    }

    initiateIcebreaker() {
        const icebreaker = this.icebreakersValue[this.icebreakerIndex];
        this.icebreakerIndex = (this.icebreakerIndex + 1) % this.icebreakersValue.length;

        this.displayIcebreaker(icebreaker);
        this.sendMessageToAllParticipants({
            action: 'icebreakerInitiated',
            icebreaker: icebreaker,
        });
    }

    displayIcebreaker(icebreaker) {
        this.api.executeCommand('showNotification', {
            title: this.icebreakerTranslationValue,
            description: icebreaker,
            type: 'success',
            timeout: 'sticky',
        });
    }

    initiateShowWhiteboard() {
        this.showWhiteboard();

        this.sendMessageToAllParticipants({
            action: 'showWhiteboardInitiated',
        });
    }

    initiateHideWhiteboard() {
        this.hideWhiteboard();

        this.sendMessageToAllParticipants({
            action: 'hideWhiteboardInitiated',
        });
    }

    showWhiteboard() {
        if (this.isWhiteboardVisible) {
            return;
        }

        this.isWhiteboardVisible = true;
        this.api.executeCommand('toggleWhiteboard');
    }

    hideWhiteboard() {
        if (!this.isWhiteboardVisible) {
            return;
        }

        this.isWhiteboardVisible = false;
        this.api.executeCommand('toggleWhiteboard');
    }

    sendMessageToAllParticipants(msg) {
        this.api.getRoomsInfo().then(rooms => {
            const msgString = JSON.stringify(msg);

            for (const room of rooms.rooms) {
                for (const participant of room.participants) {
                    // If jid is not set, this is ourselves.
                    if (!participant.jid) {
                        continue;
                    }

                    this.api.executeCommand('sendEndpointTextMessage', participant.id, msgString);
                }
            }
        });
    }

    handleLeave() {
        this.api.addEventListener('readyToClose', () => {
            this.api.dispose();
            this.launchCall();
        });
    }
}
