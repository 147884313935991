import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [
        "progress", // the progress tag
        "svgCircle" // the svg circle tag
    ];

    connect() {
        document.addEventListener('turbo:before-visit', (event) => {
            const currentUrl = window.location.href;
            const positionOfLastSlash = currentUrl.lastIndexOf('/');
            const previousStep = currentUrl.substring(positionOfLastSlash + 1);

            // set a data-attribute 'data-previous-step' to the html tag, so we know if the user navigates back or forward.
            // subtract 1 because the value of the <progress> starts at 0, but the first wizard step is 1
            document.documentElement.dataset.previousStep = parseInt(previousStep) - 1;
        }, {once: true});

        const progressValue = this.progressTarget.getAttribute('value');
        const previousStepSet = document.documentElement.hasAttribute('data-previous-step');

        if(!previousStepSet) {
            this.setStrokeDashOffset(progressValue); // on a fresh load, just set the correct value without animation
            return false;
        }

        // get the value of the data-previous-step attribute
        const previousStepValue = parseInt(document.documentElement.dataset.previousStep);

        // set the 'previous' value as inline style to the svg circle, so the animation 'knows' where to start
        this.setStrokeDashOffset(previousStepValue);

        // add the transition class to the circle
        this.svgCircleTarget.classList.add('has--transition');

        // update the circle with the new value (use timeout so the transition is visible)
        setTimeout(() => {
            // set currentStep
            this.setStrokeDashOffset(progressValue);
        }, 10);
    }

    // Set the stroke-dashoffset css property ('width' of the colored progress) inline to the svg circle
    setStrokeDashOffset(progressValue) {
        const progressMax = this.progressTarget.getAttribute('aria-valuemax') - 1;
        const svgCircleRadius = this.svgCircleTarget.getAttribute('r');
        const svgStrokeDashArray = 2 * Math.PI * svgCircleRadius;
        this.svgCircleTarget.style.strokeDasharray = svgStrokeDashArray;

        const progressPercentage = (progressValue / progressMax) * 100;
        const progressPercentageBetween15and75 = (progressPercentage * (75 - 15)) / 100 + 15;
        const svgStrokeDashOffset = (svgStrokeDashArray / 100) * progressPercentageBetween15and75;

        this.svgCircleTarget.style.strokeDashoffset = (svgStrokeDashArray - svgStrokeDashOffset) + 'px';
    }
}
