import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['buttonDiv', 'successDiv'];

    async connect() {
        if (await this.shouldShowSubscribeButton()) {
            this.buttonDivTarget.style.display = 'block';
        }
    }

    async shouldShowSubscribeButton() {
        const permission = Notification.permission;
        if (permission === 'denied') {
            // Don't ask for permission again.
            return false;
        }

        const registration = await navigator.serviceWorker.getRegistration();
        if (registration === null) {
            // Ask for permission.
            return true;
        }

        const subscription = await registration.pushManager.getSubscription();
        if (subscription === null) {
            // Ask for permission.
            return true;
        }

        // We're all set up already.
        return false;
    }

    async setupSubscription() {
        try {
            await this.requestNotificationPermission();
            await this.subscribeUserToPush();
        } catch (err) {
            this.buttonDivTarget.style.display = 'none';
            return;
        }

        console.log('Push notification subscription complete!');
        this.buttonDivTarget.style.display = 'none';
        this.successDivTarget.style.display = 'block';
    }

    async requestNotificationPermission() {
        console.log('Requesting notification permission...');
        const permission = await Notification.requestPermission();

        if (permission !== 'granted') {
            throw new Error('Permission not granted for notifications');
        }

        console.log('Notification permission granted');
    }

    async subscribeUserToPush() {
        if (!'serviceWorker' in navigator) {
            console.error('Service Worker not supported in this browser.');
            return;
        }

        console.log('Registering service worker...');
        const registration = await navigator.serviceWorker.register('/service-worker.js');

        console.log('Subscribing to push notifications...');
        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: 'BOW2Ca7Yru3kFQUAu2dywPBF9UL8S_iu31AAiB6Zo_zBnds5EkibG7fzYI_TBsMnrFTixJtW2GYZXpR73wuBxhY',
        });

        console.log('Registering push notification subscription in the backend...');
        await fetch('/subscribe-to-push-notification', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(subscription),
        });
    }
}
