import {Controller} from '@hotwired/stimulus';

export default class extends Controller {

    connect() {
        window.addEventListener('scroll#resetScrollToTop', this.resetScrollToTop);
    }

    resetScrollToTop() {
        // set a little delay because a click for page navigation takes about 100ms-500ms.
        // it feels a little more natural with a short delay of 250ms
        setTimeout(()=> {
            // reset the browser scrollbar to top position
            window.scrollTo(0, 0);
        }, 250);
    }

    disconnect() {
        window.removeEventListener('resetScrollToTop', this.resetScrollToTop)
    }
}