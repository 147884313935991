import * as am5 from "@amcharts/amcharts5";
import am5locales_de_DE from "@amcharts/amcharts5/locales/de_DE";
import am5locales_en_US from "@amcharts/amcharts5/locales/en_US";
import am5locales_es_ES from "@amcharts/amcharts5/locales/es_ES";
import am5locales_fr_FR from "@amcharts/amcharts5/locales/fr_FR";
import am5locales_it_IT from "@amcharts/amcharts5/locales/it_IT";

// Define custom theme
export class MysteryMindsTheme extends am5.Theme {
    setupDefaultRules() {
        this.rule("Graphics", ["line", "series", "stroke"]).setAll({
            strokeWidth: 3,
        });

        this.rule("Label").setAll({
            fontSize: '1rem',
            fill: am5.color(0x231f20),
            fontFamily: 'Montserrat, Verdana, Arial, sans-serif'
        });

        this.rule("Grid").setAll({
            stroke: am5.color(0x626b85),
            strokeWidth: 1,
            opacity: 1,
        });
    }
}

export function setLocale(root, language) {
    switch (language) {
        case 'de' :
            root.locale = am5locales_de_DE
            break;
        case 'en' :
            root.locale = am5locales_en_US
            break;
        case 'es' :
            root.locale = am5locales_es_ES
            break;

        case 'fr' :
            root.locale = am5locales_fr_FR
            break;

        case 'it' :
            root.locale = am5locales_it_IT
            break;
    }
}
