import {getLocale} from "./locales";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

function getDefaultFlatpickrConfig() {
    return {
        inline: true,
        allowInput: true,
        nextArrow: "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z'/></svg>",
        prevArrow: "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path d='M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z'/></svg>",
        locale: {
            ...getLocale(document.documentElement.lang).flatpickrLanguage,
            firstDayOfWeek: 1 // start week on Monday
        },
    };
}


// https://flatpickr.js.org/examples/#range-calendar
export function getFlatpickrRangeConfig() {
    return {
        ...getDefaultFlatpickrConfig(),
        mode: "range",
        inline: true,
        locale: {
            ...getLocale(document.documentElement.lang).flatpickrLanguage,
            rangeSeparator: ' - '
        },

        //Dynamically changing the locale date format
        onReady: [function (selectedDates, dateStr, instance) {
            instance.config.dateFormat = getLocale(document.documentElement.lang).flatpickrPattern;
            instance.calendarContainer.setAttribute("aria-hidden", "true");
        }],


        // Trigger event "finished" when two dates are selected to update input mask
        onChange: [function () {
            if (this.selectedDates.length === 2) {
                this.input.dispatchEvent(new Event('finished'))
            }
        }]
    };
}

// default config for month selection
// https://flatpickr.js.org/plugins/#monthselectplugin
export function getFlatpickrMonthsConfig() {
    return {
        ...getDefaultFlatpickrConfig(),
        plugins: [
            new monthSelectPlugin({
                // shorthand: true, //defaults to false
                dateFormat: "Y-m", //defaults to "F Y"
                // altFormat: "F Y", //defaults to "F Y"
                // theme: "dark" // defaults to "light"
            }),
        ],
    };
}

// single dates config
export function getFlatpickrDayConfig() {
    return {
        ...getDefaultFlatpickrConfig(),
        mode: "single",
    };
}