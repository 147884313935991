import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        // if there is no data-has-history attribute on the <html> element, set one
        if(!document.documentElement.hasAttribute("data-has-history")) {
            document.documentElement.setAttribute('data-has-history', 'true');
        }
    }
}
