import { enableSubmitButtons } from "./submit_button_disabler";
import { removeAllErrorsBeforeTurboSnapshot } from "./form_error_remover";
import '../stimulus-controllers/flatpickr_controller';

export function dispatchTurboBeforeCacheEvents () {
    document.addEventListener('turbo:before-cache', (event) => {
        const eventsToDispatch = [
            // enable all buttons inside forms which might be disabled after click / submit
            'form-loader:enable-buttons',
            // remove all form errors, above the form field, below the form field & flashes
            'form-loader:remove-all-errors',
            // close all modals
            'modal:hide-all-modals',
            // clear all password fields (login, registration)
            'password-visibility:clear-password-field',
            // close all simple toggles
            'simple-toggle:hide-all',
            // close all editable cards
            'card:close-all',
            // remove "second" Flatpickr instance on Participant's pause page
            'flatpickr:disconnect',
            // close all dropdowns, like the user dropdown in the header bar
            'dropdown:hide-all-dropdowns',
            // close all dialogs, like the support or feedback
            'dialog:hide-all-dialogs',
            // close mobile navigation
            'sidebar:hide',
        ];
        for (let key in eventsToDispatch) {
            document.dispatchEvent(new Event(eventsToDispatch[key]));
        }
    });

    document.addEventListener('form-loader:enable-buttons', (e) => enableSubmitButtons(e));
    document.addEventListener('form-loader:remove-all-errors', (e) => removeAllErrorsBeforeTurboSnapshot());
}