import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        "input", // The input which is cleared
        "button" // The 'x' button which clears the input
    ];

    clear() {
        this.inputTarget.value = '';
        this.inputTarget.focus();
        this.buttonTarget.classList.remove('is--visible');
    }

    toggleClearVisibility() {
        let inputLength = this.inputTarget.value.length;

        if(inputLength > 0) {
            this.buttonTarget.classList.add('is--visible');
        }
        else {
            this.buttonTarget.classList.remove('is--visible');
        }
    }
}
