import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        "ratingButtons",
        "matchNotHappenedEditButtons",
        "matchHappenedEditButtons",
        'yesButton',
        'noButton',
    ];

    connect() {
        let urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('r') && urlParams.has('h')) {
            this.openModalFromUrl(urlParams);
        }
    }

    toggleActions(event) {
        if (this.ratingButtonsTarget.hasAttribute('hidden')) {
            this.ratingButtonsTarget.removeAttribute('hidden');
            event.target.closest('div[data-match-rating-target]').setAttribute('hidden', true);
            return;
        }

        this.ratingButtonsTarget.setAttribute('hidden', true);
        this.showEditButtons(event);

        if (this.getUnratedMatchesBadge() !== null) {
            this.updateUnratedMatchesCount(event);
        }
    }

    showEditButtons(event) {
        if (event.detail.formSubmission.submitter === this.yesButtonTarget) {
            this.matchNotHappenedEditButtonsTarget.setAttribute('hidden', true);
            this.matchHappenedEditButtonsTarget.removeAttribute('hidden');
            return;
        }

        this.matchHappenedEditButtonsTarget.setAttribute('hidden', true);
        this.matchNotHappenedEditButtonsTarget.removeAttribute('hidden');
    }

    updateUnratedMatchesCount(event) {
        if (event.detail.formSubmission.submitter.dataset.participantHasRated === 'true') {
            return;
        }

        this.getUnratedMatchesBadge().dataset.unratedMatchesCount--;
        if (+this.getUnratedMatchesBadge().dataset.unratedMatchesCount === 0) {
            this.getUnratedMatchesBadge().style.display = 'none';
        }
    }

    openModalFromUrl(urlParams) {
        const showMeaningfulness = document.body.getAttribute('data-show-meaningfulness') === '1';
        const matchHappened = urlParams.get('h') === '1';
        const roomId = urlParams.get('r');
        this.clearUrlParams();

        if (this.ratingButtonsTarget.dataset.wasRedirectedToNonExistingEvent === '1') {
            this.dispatch('show', {target: this.element});

            return;
        }

        if (matchHappened && showMeaningfulness && this.hasYesButtonTarget) {
            let target = document.querySelector('[data-modal-id-param="meaningfulnessModal_' + roomId);
            this.dispatch('show', {target: target});
        }

        if (!matchHappened && this.hasNoButtonTarget) {
            let target = document.querySelector('[data-modal-id-param="noMatchReasonModal_' + roomId);
            this.dispatch('show', {target: target});
        }
    }

    clearUrlParams(){
        window.history.replaceState("", "Title", "/"+window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split("?")[0]);
    }

    getUnratedMatchesBadge() {
        return document.getElementById('navigation-matches-badge');
    }
}
