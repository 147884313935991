import {Controller} from "@hotwired/stimulus";
import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";
import {MysteryMindsTheme, setLocale} from "../lib/amcharts_config";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

let radarChart = null;

export default class extends Controller {
    static values = {chart: Object};

    connect() {
        const root = am5.Root.new(this.element);
        this.radarChart = root;
        setLocale(root, this.chartValue.language);

        // set our theme
        root.setThemes([MysteryMindsTheme.new(root)]);
        this.initializeChart(root);
    }

    disconnect() {
        this.radarChart.dispose();
    }

    initializeChart(root) {
        const chart = this.createChart(root);
        this.createAxes(root, chart);
        this.createSeries(root, chart);
        this.enableExport(root);

        this.setupChart(chart);
    }

    createChart(root) {
        return root.container.children.push(am5radar.RadarChart.new(root, {
            innerRadius: am5.percent(30),
            startAngle: 180,
            endAngle: 360,
        }));
    }

    createAxes(root, chart) {
        const xRenderer = am5radar.AxisRendererCircular.new(root, {});

        xRenderer.labels.template.setAll({radius: 10});
        xRenderer.grid.template.setAll({forceHidden: true});

        chart.xAxes.push(am5xy.ValueAxis.new(root, {
            renderer: xRenderer,
            min: 0,
            max: 100,
            strictMinMax: true,
            numberFormat: "#'%'",
        }));

        const yRenderer = am5radar.AxisRendererRadial.new(root, {});
        yRenderer.labels.template.setAll({
            centerX: am5.p50,
            fontWeight: "700",
            fontSize: '1rem',
        });

        yRenderer.grid.template.setAll({forceHidden: true});
        const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "category",
            renderer: yRenderer,
        }));

        yAxis.data.setAll(this.chartValue.data);
    }

    createSeries(root, chart) {
        const firstSeries = this.createSeriesInstance(root, chart, "full");
        const secondSeries = this.createSeriesInstance(root, chart, "value");

        firstSeries.columns.template.setAll({
            width: am5.p100,
            strokeOpacity: 0,
            cornerRadius: 10,
        });

        secondSeries.columns.template.setAll({
            width: am5.p100,
            strokeOpacity: 0,
            fill: am5.color(this.chartValue.color),
            tooltipText: this.chartValue.tooltipText,
            cornerRadius: 10,
            templateField: "columnSettings",
        });


        let tooltip = am5.Tooltip.new(root, {
            pointerOrientation: "left",
        });

        tooltip.get("background").setAll({
            stroke: am5.color(0x231f20),
            fillOpacity: 1,
            strokeOpacity: 0.6
        });

        secondSeries.set('tooltip', tooltip);
    }

    createSeriesInstance(root, chart, valueField) {
        return chart.series.push(am5radar.RadarColumnSeries.new(root, {
            xAxis: chart.xAxes.getIndex(0),
            yAxis: chart.yAxes.getIndex(0),
            clustered: false,
            valueXField: valueField,
            categoryYField: "category",
            fill: am5.color(0xf2f2f5),
        }));
    }

    setupChart(chart) {
        const firstSeries = chart.series.getIndex(0);
        const secondSeries = chart.series.getIndex(1);

        firstSeries.data.setAll(this.chartValue.data);
        secondSeries.data.setAll(this.chartValue.data);

        firstSeries.appear(3000, 100);
        secondSeries.appear(3000, 100);

        chart.appear(3500, this.delayValue);
    }

    enableExport(root) {
        am5plugins_exporting.Exporting.new(root, {
            menu: am5plugins_exporting.ExportingMenu.new(root, {}),
            filePrefix: this.chartValue.exportFileName,
            dataSource: this.chartValue.data,
            jpgOptions: {disabled: true},
            pdfOptions: {addURL: false},
            pdfdataOptions: {disabled: true},
            xlsxOptions: {disabled: true},
            csvOptions: {disabled: true},
            jsonOptions: {disabled: true},
            htmlOptions: {disabled: true},
            printOptions: {disabled: true},
        });
    }

}
