import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        "dialog", // The element on which the class should be toggled
    ];

    connect() {
        // register custom Event listener to close all dialogs before taking turbo snapshot
        document.addEventListener('dialog:hide-all-dialogs', (e) => this.hideAll());
    }

    show(e) {
        if(this.element.id === e.detail.dialogID) {
            // open this dialog
            this.dialogTarget.classList.add('is--visible');
            this.dialogTarget.setAttribute('aria-hidden', false);
            this.addFocusTrap(this.dialogTarget);
        }
    }

    hide(e) {
        const keyHasBeenPressed = e.type === 'keydown';
        const escapeKeyHasBeenPressed = e.keyCode === 27;

        // We're only interested in the Escape key
        if (keyHasBeenPressed && !escapeKeyHasBeenPressed) {
            return;
        }

        this.dialogTargets.forEach(dialog => {
            const dialogVisible = dialog.classList.contains('is--visible');
            const clickWasOutsideOfDialog = !dialog.contains(e.target);
            const clickWasOutsideOfHelp = e.target.closest('.help') === null;

            if ((clickWasOutsideOfDialog || escapeKeyHasBeenPressed) && dialogVisible && clickWasOutsideOfHelp) {
                this.hideAll();
            }
        });
    }

    hideAll() {
        this.dialogTargets.forEach(dialog => {
            dialog.classList.remove('is--visible');
            dialog.setAttribute('aria-hidden', true);

            // Remove the focus trap when the modal is closed
            const focusDiv = dialog.querySelector('.dialog-focus');

            if (focusDiv !== null) {
                focusDiv.remove();
            }
        });
    }

    addFocusTrap(dialog) {
        // Focus the dialog when opened
        const dialogBody = dialog.querySelector('.dialog__body');

        // wait until the transition is finished
        setTimeout(function() {
            dialog.focus();
        }, 100);

        // Add a focus trap, so the user focus stays within the modal
        let focusDiv = document.createElement('div');
        focusDiv.setAttribute("tabindex", "0");
        focusDiv.classList.add("dialog-focus");
        dialogBody.appendChild(focusDiv);

        // Return the focus to the modal-dialog when the last element is focused (focus trap)
        const dialogFocus = dialog.querySelector('.dialog-focus');
        dialogFocus.addEventListener('focus', function() {
            dialog.focus();
        });
    }
}
