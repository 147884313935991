import { ApplicationController, useDebounce } from 'stimulus-use';

export default class extends ApplicationController {
    // See https://github.com/stimulus-use/stimulus-use/blob/main/docs/use-debounce.md
    static debounces = [
        {
            name: 'submit',
            wait: 400,
        },
    ];

    connect() {
        useDebounce(this);
    }

    submit() {
        this.element.form.requestSubmit();
    }
}
