import {Controller} from '@hotwired/stimulus'
import IMask from 'imask';
import {getLocale} from "../lib/locales";

function clearInputField(inputField) {
    if (inputField !== null && inputField !== undefined) {
        inputField.value = '';
    }
}

export default class extends Controller {
    static targets = [
        'datePicker',
    ];

    connect() {
        this.inputMask()
        this.boundUpdate = this.update.bind(this)
        this.datePickerTarget.addEventListener('finished', this.boundUpdate)
    }

    disconnect() {
        this.datePickerTarget.removeEventListener('finished', this.boundUpdate);
        this.rangeDateMask?.destroy();
        clearInputField(this.datePickerTarget);
    }

    inputMask() {
        let localePattern = getLocale(this.userLanguageCode).inputMaskPattern
        this.rangeDateMask = IMask(this.datePickerTarget, {
            overwrite: false,
            autofix: true,
            eager: true, // use eager to add the separator automatically when typing
            mask: Date,
            pattern: localePattern + ' - ' + localePattern,
            validate(e) {
            }
        });
    }

    update() {
        this.rangeDateMask.updateValue()
    }

    get userLanguageCode() {
        return document.documentElement.lang
    }
}