import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static values = {
        mappings: String // { de: {element:value}, en: {...}}
    }

    // Replaces the element(which is given as a KEY from mappings object) value
    // to value (which is given as A VALUE from mappings object)
    replace(event) {
        const chosenMapping = JSON.parse(this.mappingsValue)[event.params.key];

        for (const [singleMappingKey, singleMappingValue] of Object.entries(chosenMapping)) {
            this.element.querySelector(singleMappingKey).value = singleMappingValue;
        }
    }
}
