import {Controller} from '@hotwired/stimulus';

// reference: https://www.w3.org/WAI/ARIA/apg/example-index/menu-button/menu-button-links
export default class extends Controller {
    static targets = [
        "trigger", // The button which toggles the menu
        "menu", // The dropdown menu which is opened/closed
        "menuItem", // The menu item (link, button)
    ];

    connect() {
        // register custom Event listener to close all dropdowns before taking turbo snapshot
        document.addEventListener('dropdown:hide-all-dropdowns', (e) => this.hideAll());
    }

    toggle(event) {
        event.stopPropagation();
        event.preventDefault();

        const isExpanded = this.triggerTarget.hasAttribute('aria-expanded');

        if (isExpanded) {
            this.hide();
            this.triggerTarget.focus();
        } else {
            this.hideAll(); // before showing the clicked dropdown, close all dropdowns
            document.dispatchEvent(new Event('sidebar:hide')); // hide sidebar
            document.dispatchEvent(new Event('dialog:hide-all-dialogs')); // hide all dialogs

            this.show();
        }
    }

    show() {
        this.triggerTarget.setAttribute('aria-expanded', 'true');
        this.menuTarget.classList.add('is--visible');
        this.menuTarget.setAttribute('aria-hidden', 'false');
    }

    hide() {
        this.triggerTarget.removeAttribute('aria-expanded');
        this.menuTarget.classList.remove('is--visible');
        this.menuTarget.setAttribute('aria-hidden', 'true');
    }

    hideAll() {
        const dropdowns = document.querySelectorAll('[data-controller="dropdown"]');

        dropdowns.forEach(dropdown => {
            let triggerTarget = dropdown.querySelector('[data-dropdown-target="trigger"]');
            let menuTarget = dropdown.querySelector('[data-dropdown-target="menu"]');

            triggerTarget.removeAttribute('aria-expanded');
            menuTarget.classList.remove('is--visible');
            menuTarget.setAttribute('aria-hidden', 'true');
        });
    }

    focusMenuItem(index) {
        let menuItem = this.menuItemTargets[index];
        menuItem.focus();
    }

    focusFirstMenuItem() {
        this.focusMenuItem(0);
    }

    focusLastMenuItem() {
        const menuItemLength = this.menuItemTargets.length - 1;
        this.focusMenuItem(menuItemLength);
    }

    focusNextMenuItem(currentItem) {
        let index = this.menuItemTargets.indexOf(currentItem);
        let isLastItem = this.menuItemTargets.length - 1 === index;

        if(isLastItem) {
            this.focusFirstMenuItem();
        }
        else {
            let nextItem = this.menuItemTargets[index+1];
            nextItem.focus();
        }
    }

    focusPreviousMenuItem(currentItem) {
        let index = this.menuItemTargets.indexOf(currentItem);

        if(index === 0) {
            this.focusLastMenuItem();
        }
        else {
            this.focusMenuItem(index - 1);
        }
    }

    // menu trigger button keydown events
    triggerKeydown(event) {
        let key = event.key;
        let flag = false;

        switch (key) {
            case ' ':
            case 'Enter':
            case 'ArrowDown':
            case 'Down':
                this.show();
                this.focusFirstMenuItem();
                flag = true;
                break;
            case 'Up':
            case 'ArrowUp':
                this.show();
                this.focusLastMenuItem();
                flag = true;
                break;
            case 'Esc':
            case 'Escape':
                this.hide();
                this.triggerTarget.focus();
                flag = true;
                break;
            default:
                break;
        }

        if (flag) {
            // "disable" all unwanted keys
            event.stopPropagation();
            event.preventDefault();
        }
    }

    // keydown events for all role="menuItem" elements within the menu
    menuItemKeydown(event) {
        let target = event.currentTarget;
        let key = event.key;
        let flag = false;

        switch (key) {
            case ' ':
                window.location.href = target.href;
                break;

            case 'Esc':
            case 'Escape':
                this.hide();
                this.triggerTarget.focus();
                flag = true;
                break;

            case 'Up':
            case 'ArrowUp':
                this.focusPreviousMenuItem(target);
                flag = true;
                break;

            case 'ArrowDown':
            case 'Down':
                this.focusNextMenuItem(target);
                flag = true;
                break;

            case 'Home':
            case 'PageUp':
                this.focusFirstMenuItem();
                flag = true;
                break;

            case 'End':
            case 'PageDown':
                this.focusLastMenuItem();
                flag = true;
                break;

            case 'Tab':
                this.hide();
                break;

            default:
                break;
        }

        // "disable" all unwanted keys
        if (flag) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    // Open dialogs from e.g. within a dropdown
    openDialog(event) {
        // the id for the dialog is passed via 'data-dropdown-id-param' in help.html
        this.dispatch("openDialog", { detail: { dialogID: event.params.id } })
    }
}
