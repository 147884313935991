import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        "toggle", // The button which toggles the sidebar on mobile
        "sidebar", // The sidebar which is opened/closed on mobile
    ];

    connect() {
        // register custom Event listener to close the mobile navigation before taking turbo snapshot
        document.addEventListener('sidebar:hide', (e) => this.hideAll());
    }

    toggle(event) {
        event.stopPropagation();
        event.preventDefault();

        const isExpanded = this.toggleTarget.hasAttribute('aria-expanded');

        // first, hide all open lists
        this.hideAll();

        if (isExpanded) {
            this.toggleTarget.focus();
        } else {
            document.dispatchEvent(new Event('dropdown:hide-all-dropdowns')); // hide all dropdowns
            document.dispatchEvent(new Event('dialog:hide-all-dialogs')); // hide all dialogs

            this.show();
        }
    }

    show() {
        document.body.classList.add('scroll-mobile--disabled');
        this.toggleTarget.setAttribute('aria-expanded', 'true');
        this.sidebarTarget.classList.add('is--visible');
        this.sidebarTarget.focus();
    }

    hideAll() {
        document.body.classList.remove('scroll-mobile--disabled');
        this.toggleTarget.removeAttribute('aria-expanded');
        this.sidebarTarget.classList.remove('is--visible');
    }

    triggerKeydown(e) {
        const escapeKeyHasBeenPressed = e.keyCode === 27;

        if(escapeKeyHasBeenPressed) {
            this.hideAll();
        }
    }
}