import {Controller} from "@hotwired/stimulus";
import "flatpickr";
import {getFlatpickrDayConfig, getFlatpickrMonthsConfig, getFlatpickrRangeConfig,} from "../lib/flatpickr_configs";

export function destroyInstance(flatpickrElement) {
    if (flatpickrElement !== null && flatpickrElement !== undefined) {
        flatpickr(flatpickrElement).destroy();
    }
}

export default class extends Controller {
    connect() {
        const displayAttribute = this.element.getAttribute('data-display');
        // we must get the date format from <html> attributes (for single datepicker, that is) right here
        const dateFormat = document.documentElement.getAttribute('data-date-format');
        const dateMonthFormat = document.documentElement.getAttribute('data-date-month-format');
        const disablePastDates = this.element.hasAttribute("data-disable-past-dates") &&
            this.element.getAttribute("data-disable-past-dates") === "true";

        switch (displayAttribute) {
            case 'Month':
                flatpickr(this.element, {
                    ...getFlatpickrMonthsConfig(),
                    ...{
                        dateFormat: dateMonthFormat
                    }
                });
                break;

            case 'Range':
                flatpickr(this.element, {
                    ...getFlatpickrRangeConfig(),
                    ...{
                        dateFormat: dateFormat
                    }
                });
                break;

            case 'Day':
                flatpickr(this.element, {
                    ...getFlatpickrDayConfig(),
                    ...{
                        dateFormat: dateFormat,
                        minDate: disablePastDates ? "today" : null,
                        onChange: (selectedDates, dateStr, instance) => {
                            if (!dateStr) {
                                instance.setDate("today");  // reset to today if the input is invalid
                            }
                        }
                    }
                });
                break;

            default:
                throw new Error('Unknown display type: ' + displayAttribute);
        }

        document.addEventListener('flatpickr:disconnect', (e) => destroyInstance(this.element));
    }

    disconnect() {
        destroyInstance(this.element);
    }
}